"use strict";

/**
 * @class GoogleAddress
 * @author Tariye George
 * @see: https://developers.google.com/maps/documentation/javascript/geocoding
 * @see: https://developers.google.com/maps/documentation/geocoding/start
 * @see: https://developers.google.com/maps/documentation/geocoding/requests-geocoding
 */
const GoogleAddress = {
  addressLookup: (
    { addressLine1, postCode, country },
    GetAddress,
    callBack,
    simpleCoords = false
  ) => {
    axios
      .get(
        `${server.serverJobBooking}google-address/${addressLine1}/${postCode}/${country}`
      )
      .then((res) => {
        switch (true) {
          case Number(res.status) !== 200:
          case !Array.isArray(res.data):
          case res.data.length === 0:
            callBack(null);
            GetAddress.failOver();
            return false;
          default:
            break;
        }

        let postcode, latitude, longitude;

        let addressData = [];
        for (const data of res.data) {
          if (simpleCoords) {
            return callBack({
              latitude: data.address.addressLatitude,
              longitude: data.address.addressLongitude
            });
          }

          const objCountry = GetAddress.countries
            .filter((c) => c.name === data.address.country)
            .shift();

          let { address } = data;

          postcode = postcode || address.postCode;
          latitude = latitude || address.addressLatitude;
          longitude = longitude || address.addressLongitude;

          delete address.country;
          address.countryId = objCountry.countryId;

          addressData.push(address);
        }

        GetAddress.addressData = {
          postcode,
          latitude,
          longitude,
          addresses: addressData
        };

        let addressList = GoogleAddress.addressList(addressData);

        return callBack(addressList);
      })
      .catch(() => {
        callBack(null);
        GetAddress.failOver();
        return false;
      });
  },

  addressList(addressData) {
    let html = [];

    for (const [index, data] of Object.entries(addressData)) {
      let innerText = [data.addressLine1, data.addressLine2, data.country].join(
        ","
      );
      let listItem = document.createElement("a");

      listItem.setAttribute("data-address-index", index);
      listItem.classList.add("dropdown-item", "js-address");
      listItem.innerText = innerText;
      html.push(listItem);
    }

    return html;
  }
};

export default GoogleAddress;
