// Grid Reporting Version 2
import { renderGridTable } from "../../ag-grid-render/render-grid-table";
import { agGridColumnDefs } from "../../ag-grid-render/ag-grid-column-defs/ag-grid-column-defs";
import GridTable from "../../single-components/ag-grid/grid-table";
import toast from "../../toast";
import userAccess from "../../userAccess";

const associatedJobsBoard = {
  renderTable(id) {
    // Specify the column headers
    const columnDefs = agGridColumnDefs({
      pageInstance: this,
      tableRef: "associatedJobsBoard"
    });

    const options = {
      columnDefs: columnDefs,
      sideBar: false,
      suppressColumnsToolPanel: true,
      rowModelType: "serverSide",
      cacheBlockSize: 100,
      maxBlocksInCache: 10,
      blockLoadDebounceMillis: 100,
      serverSideDatasource: {
        getRows(params) {
          const request = params.request;
          const searchValue =
            params.parentNode.gridOptionsWrapper.gridOptions.searchValue;

          request.endRow = options.cacheBlockSize;
          request.searchValue = searchValue ? searchValue : null;

          request.valueCols = params.parentNode.columnApi
            .getAllColumns()
            .map((e) => {
              return {
                id: e.colDef.field,
                field: e.colDef.field,
                displayName: e.colDef.headerName
              };
            });

          //child rows
          if (params.parentNode.level == 0) {
            const jobId = params.parentNode.data.JOB_ID;
            let rows = {};
            axios
              .get(`${server.serverJobBooking}jobBoardChildRows/${jobId}`)
              .then((response) => {
                rows = response.data;
                params.successCallback(rows.jobs, rows.jobs.length);
              });
            return;
          }

          const query = {
            invoice: id,
            type: "all",
            valueCols: request.valueCols,
            filterModel: {},
            invoiceFilter: [],
            groupKeys: [],
            pivotCols: [],
            pivotMode: false,
            searchValue: null,
            sortModel: []
          };

          const postData = {
            request: { ...query }
          };

          //build invoice type dropdown - CHANGE TO CREATE INVOICE API
          axios
            .post(`${server.serverJobBooking}job-board`, postData)
            .then((res) => {
              const jobs = res.data.jobs.topRowData.map((job) => {
                return {
                  ...job,
                  ...{ PO_IND: job.PO_VALIDATION_IND },
                  ...{ batchInvoicing: job.BATCH_JOBS_ON_INVOICE_IND },
                  ...{ jobId: job.JOB_ID },
                  ...{
                    BILLING_ACCOUNT: job.PARENT_ACCOUNT
                      ? job.PARENT_ACCOUNT[0].ACCOUNT_NAME
                      : job.ACCOUNT_NAME
                  },
                  ...{
                    parentId: job?.PARENT_ACCOUNT
                      ? job.PARENT_ACCOUNT[0].ACCOUNT_AREA_ID
                      : job.ACCOUNT_AREA_ID
                  },
                  ...{
                    PARENT_PO_VALIDATION_DESCRIPTION: job.PARENT_ACCOUNT
                      ? job.PARENT_ACCOUNT[0].PO_VALIDATION_DESCRIPTION
                      : job.PO_VALIDATION_DESCRIPTION
                  },
                  ...{
                    INVOICE_TYPE: job.PARENT_ACCOUNT
                      ? job.PARENT_ACCOUNT[0].INVOICE_TYPE
                      : job.INVOICE_TYPE
                  },
                  ...{
                    INVOICE_TYPE_ID: job.PARENT_ACCOUNT
                      ? job.PARENT_ACCOUNT[0].INVOICE_TYPE_ID
                      : job.INVOICE_TYPE_ID
                  },
                  ...{
                    INVOICE_SCHEDULE: job.PARENT_ACCOUNT
                      ? job.PARENT_ACCOUNT[0].INVOICE_SCHEDULE
                      : job.INVOICE_SCHEDULE
                  },
                  ...{
                    INVOICE_SCHEDULE_ID: job.PARENT_ACCOUNT
                      ? job.PARENT_ACCOUNT[0].INVOICE_SCHEDULE_ID
                      : job.INVOICE_SCHEDULE_ID
                  },
                  ...{
                    PO_VALIDATION_IND: job.PARENT_ACCOUNT
                      ? job.PARENT_ACCOUNT[0].PO_VALIDATION_IND
                      : job.PO_VALIDATION_IND
                  },
                  ...{
                    POD_REQUIRED_IND: job.PARENT_ACCOUNT
                      ? job.PARENT_ACCOUNT[0].POD_REQUIRED_IND
                      : job.POD_REQUIRED_IND
                  },
                  ...{ currentTab: self.tab }
                };
              });
              const lastRowIndex = request.startRow + jobs.length;

              if (jobs.length)
                params.successCallback(
                  jobs,
                  jobs.length === options.cacheBlockSize ? -1 : lastRowIndex
                );
              else params.successCallback(jobs, lastRowIndex);
            })
            .catch(() => {
              toast.displayToast({
                type: "error",
                titleText: "Error",
                bodyMessageText:
                  "There was an error while viewing the associated jobs. Please try again."
              });
            });
        }
      }
    };

    renderGridTable({
      pageInstance: this,
      gridParams: {
        tableReference: "associatedJobsBoard",
        holder: document.querySelector(
          "#confirmation-modal .js-grid-table-invoice"
        ),
        agGridOptions: options,
        searchTable: true
      },
      contextMenuOverride: this.getMenuItems.bind(this)
    });
  },

  getMenuItems() {
    if (userAccess.isUserRole(5)) return [];

    const exportParams = {
      fileName: `TMS - Associated Jobs ${moment().format("YYYY-MM-DD")}`
    };

    const contextMenuItems = [];

    contextMenuItems.push(
      "separator",
      {
        name: "Export to Excel",
        icon: '<i class="fal fa-file-export"></i>',
        action: () => {
          this.gridOptions.api.exportDataAsExcel(exportParams);
        }
      },
      "separator",
      {
        name: "Export to CSV",
        icon: '<i class="fal fa-file-export"></i>',
        action: () => {
          this.gridOptions.api.exportDataAsCsv(exportParams);
        }
      }
    );

    return contextMenuItems;
  },

  lookups() {
    return Promise.all([
      axios
        .get(`${server.serverCustomer}currency`)
        .then(({ data: { data } }) => {
          this.addToState({ data, name: "currency", id: "currencyId" });
        })
        .catch((err) => {
          console.error(err);
          toast.displayToast({
            type: "error",
            titleText: "Error retrieving Currencies",
            bodyMessageText:
              "There was an error retrieving the account currencies. Please reload the page to try again."
          });
        })
    ]);
  },

  renderCurrencyField(params, field) {
    if (!params.node.group && !params.node.rowPinned) {
      return "-";
    }
    const currencyId = this.renderParentText(params, { field });
    const val = this.renderParentText(params);
    return GridTable.currencyFormatRender(val, true, {
      currency: this.currency[currencyId]?.isoCurrencyCode
    });
  },

  renderAdditionalCost(params) {
    if (params.node.group !== true && !params.node.rowPinned) {
      return "-";
    }
    let totalCost = 0.0;

    if (params.node.group !== true) return;
    const currencyId = this.renderParentText(params, {
      field: "HAULIER_CURRENCY_ID"
    });
    const { ADDITIONAL_COSTS } = params?.data;

    if (ADDITIONAL_COSTS) {
      ADDITIONAL_COSTS.map((row) => {
        totalCost += parseFloat(row.ADDITIONAL_COSTS_TOTAL) || 0;
      });
    } else {
      totalCost = params?.data?.ADDITIONAL_COSTS_TOTAL;
    }

    return GridTable.currencyFormatRender(totalCost, true, {
      currency: this.currency[currencyId]?.isoCurrencyCode
    });
  },

  renderParentText(params, { field } = {}) {
    const columnId = field || params.colDef.field;
    const data = params?.data;
    if (
      params.node.group &&
      Object.keys(params.node.childrenMapped).length == 0
    ) {
      const childrenAfterFilter = params.node.childrenAfterFilter;
      if (childrenAfterFilter && childrenAfterFilter.length > 0) {
        let tempChildren = {};
        for (let index = 0; index < childrenAfterFilter.length; index++) {
          const _childrenAfterFilter = childrenAfterFilter[index];
          if (index == 0) {
            tempChildren[columnId] = _childrenAfterFilter.data[columnId];
          } else {
            if (tempChildren[columnId] != _childrenAfterFilter.data[columnId]) {
              return "-";
            } else {
              continue;
            }
          }
        }
        let value = params.node.childrenAfterFilter[0]
          ? params.node.childrenAfterFilter[0].data[columnId]
          : "";
        value = value ? value : "";
        return value;
      }
    }
    if (!data) {
      return "-";
    } else {
      return params?.data[columnId] == "" || params?.data[columnId] == null
        ? "-"
        : params?.data[columnId];
    }
  },

  addState({ data, id }) {
    const arr = [];

    for (const type of data) {
      arr[type[id]] = type;
    }
    return arr;
  },

  addToState({ data, name, id }) {
    this[name] = this.addState({ data, id });
  },

  showPlaceHolder(params) {
    const { value } = params;

    if (value) {
      return value;
    }

    return "-";
  },

  renderAdditionalCostNotes(params) {
    if (params.node.group !== true) {
      return "-";
    }
    let additionalCostNotes = [];
    const { ADDITIONAL_COSTS } = params?.data;
    if (ADDITIONAL_COSTS) {
      ADDITIONAL_COSTS.map((row) => {
        const { DESCRIPTION } = row;
        if (DESCRIPTION) additionalCostNotes.push(DESCRIPTION);
      });
    }
    return additionalCostNotes.length ? additionalCostNotes.join(", ") : "-";
  }
};

export default associatedJobsBoard;
