import { debounce } from "lodash";
import hubspotService from "../../../services/customers/hubspotService";
import { fillInFields, insertValue } from "../../forms";
import toast from "../../toast";
import userAccess from "../../userAccess";

class HubspotSearch {
  types = Object.freeze({
    CONTACT: "contact",
    COMPANY: "company"
  });

  constructor() {
    this.searchTrigger = document.querySelector(".js-hubspot-company-search");
    this.searchInput = document.querySelector(".hubspot-company-search-input");
    this.searchResults = document.querySelector(".js-hubspot-company-list");

    this.dynamicModalTitle = document.querySelector(
      ".js-dynamic-hubspot-search-title"
    );
    this.dynamicSearchLabel = document.querySelector(
      ".js-dynamic-hubspot-search-label"
    );
  }

  init() {
    if (this.isInitialized) {
      console.warn("Hubspot search already initialized");
      return;
    }

    this.isInitialized = true;

    const searchDebounce = debounce(this.searchHubspot.bind(this), 500);
    this.searchTrigger.addEventListener("click", () => {
      const query = this.searchInput.value.trim();
      if (!query) {
        searchDebounce.cancel();
        return toast.displayToast({
          type: "warning",
          titleText: "Empty search",
          bodyMessageText: "Please enter a search query."
        });
      }

      searchDebounce(query);
    });

    this.searchResults.addEventListener("click", (e) => {
      const el = e.target;
      if (el.closest(".dropdown-item")) {
        e.preventDefault();
        this.onSelect(el);
        this.close();
      }
    });
  }

  search(filterValue) {
    const payload = {
      filterValue,
      hubspotContactOwnerId: this.hubspotContactOwnerId
    };
    if (this.type === this.types.CONTACT) {
      return hubspotService.searchContacts(payload);
    }
    return hubspotService.searchCompanies(payload);
  }

  async searchHubspot(query) {
    const results = await this.search(query);
    this.results = results;

    if (!results.length) {
      jQuery(".js-hubspot-no-results").text(
        `No ${this.type} found in hubspot.`
      );
      return toast.displayToast({
        type: "warning",
        titleText: `No ${this.type} found`,
        bodyMessageText: `No ${this.type} found in hubspot.`
      });
    }

    jQuery(".js-hubspot-no-results").text("");
    if (this.type === this.types.COMPANY) {
      this.searchResults.innerHTML = results
        .map(
          (c) =>
            `<a data-value="${c.id}" data-checkType="${this.types.COMPANY}" class="dropdown-item" type="button"><b>${c.properties.name}</b></a>`
        )
        .join("");
    } else {
      this.searchResults.innerHTML = `<a data-value="${results[0].hubspotContactId}" data-checkType="${this.types.CONTACT}" class="dropdown-item" type="button"><b>${results[0].firstName} ${results[0].lastName}, ${results[0].email}</b></a>`;
    }
  }

  open({ modalTitle, searchLabel, type, hubspotContactOwnerId, onSelect }) {
    this.onSelect = onSelect;
    this.type = type;
    this.hubspotContactOwnerId = hubspotContactOwnerId;
    insertValue(this.searchInput, "");
    this.searchResults.innerHTML = "";

    this.dynamicModalTitle.innerHTML = modalTitle;
    this.dynamicSearchLabel.innerHTML = searchLabel;
    jQuery(".js-hubspot-no-results").text("");
    jQuery("#hubspot-company-search").modal("show");
  }

  close() {
    jQuery("#hubspot-company-search").modal("hide");
  }

  isCompanyType(type) {
    return type === this.types.COMPANY;
  }

  /**
   * @param {HTMLElement} el
   * @param {string | number} id
   * @returns
   */
  toggleHubspotLink(el, id) {
    if (!el) return;

    if (id && userAccess.isSystemAdmin()) {
      el.classList.remove("d-none");
      el.textContent = el.dataset.unlinkLabel;
    } else if (id) {
      el.classList.add("d-none");
    } else {
      el.classList.remove("d-none");
      el.textContent = el.dataset.linkLabel;
    }
  }

  checkHubspotLink({ hubspotId, type, typeId, trigger }) {
    let success = true;
    return axios
      .get(
        `${server.serverCustomer}hubspot/companyLinkCheck/${type}/${hubspotId}/${typeId}`
      )
      .then((res) => {
        const hubspotLink = res.data.data;
        const isCompany = this.isCompanyType(type);
        let finalType = isCompany ? this.types.COMPANY : this.types.CONTACT;
        finalType = finalType.charAt(0).toUpperCase() + finalType.slice(1);

        let toastType = "success";
        let toastTitle = `Hubspot ${finalType} linked`;
        let bodyMessageText = `The ${finalType} has been linked successfully`;
        if (hubspotLink.length) {
          success = false;
          if (hubspotLink[0].companyLinkedDifferentBranch) {
            toastType = "error";
            toastTitle = "Error";
            bodyMessageText = res.data.message;
          } else {
            toastType = "warning";
            toastTitle = `${finalType} already linked`;
            bodyMessageText = isCompany
              ? `This ${finalType} is already linked to another customer: ${hubspotLink[0].account[0].accountName}`
              : "Hubspot contact already linked to an existing TMS contact.";
          }
        } else {
          if (isCompany) {
            fillInFields({
              name: "hubspotCompanyId",
              obj: { hubspotCompanyId: hubspotId }
            });
          } else {
            const hubspotContact = this.results.find(
              (r) => parseInt(r.hubspotContactId) === parseInt(hubspotId)
            );
            const fields = {
              hubspotContactId: "hubspotContactId",
              firstName: "firstName",
              lastName: "lastName",
              email: "email",
              phone: "phone"
            };
            for (let field in fields) {
              if (hubspotContact[field])
                fillInFields({
                  name: field,
                  obj: hubspotContact
                });
            }
          }

          this.toggleHubspotLink(trigger, hubspotId);
        }

        toast.displayToast({
          type: toastType,
          titleText: toastTitle,
          bodyMessageText: bodyMessageText
        });

        return success;
      });
  }
}

const hubspotSearch = new HubspotSearch();

export default hubspotSearch;
