import serverCheck from "../../modules/serverCheck";
import BaseService from "../BaseService";

class HubspotService extends BaseService {
  baseUrl = `${serverCheck.getServer().serverCustomer}hubspot`;

  async createCompanyByAccountAreaId(accountAreaId) {
    const accountAreaResponse = await this.httpClient.post(
      `/account-area/${accountAreaId}/company`
    );
    return accountAreaResponse.data.data;
  }

  async unlinkAccountFromCompany({ hubspotCompanyId, portalId }) {
    const res = await this.httpClient.delete(`/companies/${hubspotCompanyId}`, {
      params: { portalId }
    });
    return res.data.data;
  }

  async unlinkContact({ hubspotContactId, portalId }) {
    const res = await this.httpClient.delete(`/contacts/${hubspotContactId}`, {
      params: { portalId }
    });
    return res.data.data;
  }

  async searchContacts({ filterValue, hubspotContactOwnerId }) {
    const res = await this.httpClient.post("/searchContact", {
      filterValue,
      hubspotContactOwnerId
    });
    return res.data.data;
  }

  async searchCompanies({ filterValue, hubspotContactOwnerId }) {
    const res = await this.httpClient.post("/searchCompany", {
      filterValue,
      hubspotContactOwnerId
    });
    return res.data.data;
  }
}

const hubspotService = new HubspotService();

export default hubspotService;
