"use strict";
import jobBookingCodeBlocks from "./job-booking/Utils/jobBookingCodeBlocks";
import jobBookingUtils from "./job-booking/Utils/jobBookingUtils";
import forms, { shrinkLabels } from "../forms";
import pageLoaders from "../pageLoaders";
import server from "../serverCheck";
import authentication from "../authentication";
import GoogleAddress from "./GoogleAddress";
import toast from "../toast";

const hasProperty = (obj, property) =>
  Object.prototype.hasOwnProperty.call(obj, property);

class GetAddressClass {
  constructor() {
    this.addressData = [];
    this.ready = axios
      .get(`${server.getServer().serverCustomer}country`, {
        headers: {
          Authorization: "Bearer " + authentication.getDetails("token")
        }
      })
      .then((res) => (this.countries = res.data.data));
  }

  /**
   *
   * @returns {string}
   */
  static get apiKey() {
    return "txeWpnD0HEiI9ZhRiIS1aQ27618";
  }

  static get hostname() {
    return "api.getAddress.io";
  }

  /**
   * Request config
   * @returns {Object}
   */
  static get params() {
    return {
      "api-key": GetAddressClass.apiKey,
      format: false,
      sort: true,
      expand: true
    };
  }

  /**
   * Get building identifier (if any) for query
   * @param building
   * @param business
   * @returns {*|null}
   */
  filter(building, business) {
    if (building !== undefined) {
      return building;
    } else if (business !== undefined) {
      return business;
    } else {
      return null;
    }
  }

  /**
   * Search for info by postcode
   * @param postCode
   * @param building
   * @param business
   * @param callBack
   */
  doLookup({ postCode, building, business }, callBack) {
    const filter = this.filter(building, business) || "";
    const url = `find/${postCode}/${filter}`;

    /** @type {import("axios").AxiosRequestConfig} */
    const options = {
      url,
      baseURL: "https://api.getAddress.io/",
      method: "GET",
      params: GetAddressClass.params
    };

    axios(options)
      .then(({ data }) => {
        const { Message, postcode } = data;
        if (Message) throw new Error();
        if (postcode) return callBack(data);
        callBack(null);
      })
      .catch(() => {
        this.failOver();
        callBack(null);
      });
  }

  /**
   *
   * @param addressData
   * @returns {HTMLAnchorElement[]|Boolean}
   */
  addressList(addressData) {
    switch (true) {
      case hasProperty(addressData, "postcode"):
        this.addressData = addressData;
        return jobBookingCodeBlocks.getAddressList(addressData.addresses);
      case document
        .querySelector("#confirmation-modal")
        .classList.contains("show"):
        return false;
      case hasProperty(addressData, "status"):
      default:
        return jobBookingCodeBlocks.noAddressList();
    }
  }

  addressListClick({ listItem, getState, postCodeInput }) {
    let addressModal = postCodeInput.closest(".modal-dialog");
    let container = listItem.closest(".js-address-suggest");
    let selectedIndex = listItem.getAttribute("data-address-index");

    switch (true) {
      case listItem.getAttribute("data-disabled") === "true":
      case listItem.getAttribute("data-role") === "cancel":
        this.deleteSuggestions(container, postCodeInput);
        return false;
      default:
        break;
    }

    let formattedAddress = hasProperty(
      this.addressData.addresses[selectedIndex],
      "line_1"
    )
      ? jobBookingUtils.translateGetAddress(this.addressData, selectedIndex)
      : this.addressData.addresses[selectedIndex];

    for (let element of Object.values(
      addressModal.querySelectorAll(".error")
    )) {
      for (let lbl of element.querySelectorAll(".reason")) {
        lbl.remove();
      }
      element.classList.remove("error");
    }

    for (let field of Object.keys(formattedAddress)) {
      if (!getState.supplier) {
        if (getState && hasProperty(getState(), "newAddress")) {
          getState().newAddress[field] = formattedAddress[field];
        }
      }

      let input = addressModal.querySelector(`[name=${field}]`);

      if (input) {
        forms.insertValue(input, formattedAddress[field]);
      }
    }

    this.changed = true;
    this.deleteSuggestions(container, postCodeInput);
  }

  deleteSuggestions(container) {
    jQuery(container).closest(".input-base").find(".dropdown-toggle").click();
    container.innerHTML = "";
  }

  failOver() {
    toast.displayToast({
      type: "warning",
      titleText: "Warning",
      bodyMessageText:
        "There has been an error retrieving address details. <br/>Please try again.</p>"
    });

    pageLoaders.removeLoader();
    return false;
  }

  addressLookup(postCodeInput, container, getState) {
    if (!container) return;

    let postCode = postCodeInput.value;
    const validPostCode = forms.validateInput(postCodeInput);

    const form = postCodeInput.closest("form");
    const addressLine1Input = form.querySelector("[name=addressLine1]");
    const countryIdInput = form.querySelector("[name=countryId]");
    const countryId = parseInt(countryIdInput.value);

    postCodeInput.addEventListener("change", () => {
      shrinkLabels(postCodeInput.closest("label"));
      tailSelect(countryIdInput).reload();
    });

    if (countryId !== 12 && !isNaN(countryId)) {
      const validAddressLine1 = forms.validateInput(addressLine1Input);
      const validCountryId = forms.validateInput(countryIdInput);

      if (validPostCode && validAddressLine1 && validCountryId) {
        const country = () => {
          if (typeof getState === "object") {
            return getState.countries.state.options[countryId - 1];
          } else {
            return getState().countries[countryId];
          }
        };

        const addressData = {
          addressLine1: addressLine1Input.value,
          country: country().name,
          postCode
        };

        pageLoaders.displayLoader("Getting data");

        GoogleAddress.addressLookup(addressData, this, (addressList) => {
          this.deleteSuggestions(container, postCodeInput);
          this.renderItems(addressList, getState, postCodeInput, container);
        });
      }

      return true;
    } else if (countryId === 12 || isNaN(countryId)) {
      if (validPostCode) {
        pageLoaders.displayLoader("Getting data");

        /** go to GetAddress */
        this.doLookup({ postCode }, (response) => {
          this.deleteSuggestions(container, postCodeInput);

          if (!response) return false;

          const addressList = this.addressList(response);

          this.renderItems(addressList, getState, postCodeInput, container);
        });
      }

      return true;
    }
  }

  renderItems(addressList, getState, postCodeInput, container) {
    if (addressList) {
      for (let item of addressList) {
        item.addEventListener("click", (e) => {
          let listItem = e.target;
          this.addressListClick({ listItem, getState, postCodeInput });
        });
        container.appendChild(item);
      }
      container.classList.add("show");
    }
    pageLoaders.removeLoader();
  }

  static get Instance() {
    return new GetAddressClass();
  }
}

export default GetAddressClass;
